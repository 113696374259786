import { runningInShopify } from "./runTime";

export const setupFAB = () => {
  document.querySelector(".ha-fab")?.addEventListener("click", () => {
    const content = document.querySelector(".ha-fab-content");
    if (content?.classList.contains("ha-fab-content-hidden")) {
      content?.classList.remove("ha-fab-content-hidden");
    } else {
      content?.classList.add("ha-fab-content-hidden");
    }
  });
  document.querySelector(".ha-fab-close")?.addEventListener("click", () => {
    const fabWrapper = document.querySelector(".ha-fab-wrapper");
    fabWrapper?.classList.add("ha-hidden");
  });

  if (!runningInShopify()) {
    document.querySelector(".ha-fab-wrapper")?.classList.remove("ha-hidden");
  }
};
