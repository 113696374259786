import queryString from "query-string";
import {
  shopifyThemeConfigs as settings,
  ShopifyConfig as Config,
} from "./shopifyThemeSettings";
import { runningInShopify } from "./runTime";

type Setting<T extends keyof Config> = {
  id: T;
  label: string;
  setting: `{{ section.settings.${T} }}`;
  options: { label: string; value: Config[T] }[];
  default: Config[T];
};

export type ShopifySetting = Setting<keyof Config>;

const getContainer = () => {
  const titleText = "Configuration Options";
  const containerName = "ha-config-wrapper";

  const container = document.querySelector<HTMLDivElement>(`.${containerName}`);
  if (container) return container;

  const newContainer = document.createElement("div");
  newContainer.className = containerName;

  const title = document.createElement("h2");
  title.className = "ha-configs-title";
  title.innerText = titleText;
  newContainer.appendChild(title);

  document.querySelector(".ha-fab-content")!.appendChild(newContainer);
  return newContainer;
};

const handleConfig = <T extends keyof Config>(
  { id, label, options, default: defaultOption }: Setting<T>,
  parsedQuery: queryString.ParsedQuery,
  container: HTMLDivElement
) => {
  const selection = (parsedQuery[id] ?? defaultOption) as typeof defaultOption;

  const s = document.querySelector(`.ha-config-select-${id}`);
  if (s) return selection;

  const title = document.createElement("h3");
  title.classList.add("ha-config-title");
  title.classList.add(`ha-config-title-${id}`);
  title.innerText = label + ":";

  const select = document.createElement("select");
  title.classList.add("ha-config-select");
  title.classList.add(`ha-config-select-${id}`);
  select.id = id + "Select";
  select.onchange = (e) => {
    const newQuery = queryString.stringify({
      ...parsedQuery,
      [id]: select.value,
    });

    if (location.search !== newQuery) {
      location.search = newQuery;
    }
  };

  container.appendChild(title);
  container.appendChild(select);

  for (var i = 0; i < options.length; i++) {
    var option = document.createElement("option");
    option.value = options[i].value;
    option.text = options[i].label;
    select.appendChild(option);
  }

  select.value = selection;
  return selection;
};

const getConfig = () => {
  const s = {} as Config;
  if (runningInShopify()) {
    for (let i = 0; i < settings.length; i++) {
      const setting = settings[i];
      s[setting.id] = setting.setting as any;
    }
    return s;
  }

  const parsedQuery = queryString.parse(location.search);
  const conf = queryString.stringify(parsedQuery);

  const container = getContainer();

  for (let i = 0; i < settings.length; i++) {
    const setting = settings[i];
    s[setting.id] = handleConfig(setting, parsedQuery, container);
  }

  const newConf = queryString.stringify(s);
  if (conf !== newConf) location.search = newConf;

  return s;
};
const config = getConfig();

export const shopifyConfig = config;
