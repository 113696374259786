import { ShopifySetting } from "./shopifyConfig";

export type ShopifyConfig = {
  units: "US" | "SI";
};

export const shopifyThemeConfigs: ShopifySetting[] = [
  {
    id: "units",
    label: "Units",
    setting: "{{ section.settings.units }}",
    options: [
      { value: "SI", label: "International" },
      { value: "US", label: "US" },
    ],
    default: "SI",
  },
];
