import { setupFAB } from "./fabConfig";
import {
  BoardType,
  FliteCell,
  Propeller,
  Weight,
  WingType,
} from "./graphDataGen";
import {
  UserConfiguration,
  handleBoardType,
  handleFliteCellButtons,
  handlePropellerButtons,
  handleRiderWeight,
  handleWingType,
  updateUI,
} from "./handleUI";

import { LineChart } from "./plotLineChart";
import { shopifyConfig } from "./shopifyConfig";

let userConfiguration: UserConfiguration = {
  boardType: BoardType.Fliteboard,
  propeller: Propeller.TrueGlideProp,
  weight: shopifyConfig.units === "SI" ? 80 : 180,
  fliteCell: FliteCell.Explore,
  wingType: WingType.CruiserJet1800,
};

const setBoardType = (boardType: BoardType) => {
  userConfiguration.boardType = boardType;
  updateUI(userConfiguration);
  render();
};

const setSelectedPropeller = (propeller: Propeller) => {
  userConfiguration.propeller = propeller;
  updateUI(userConfiguration);
  render();
};

const setWingType = (wingType: WingType) => {
  userConfiguration.wingType = wingType;
  updateUI(userConfiguration);
  render();
};

const setRiderWeight = (weight: Weight) => {
  userConfiguration.weight = weight;
  updateUI(userConfiguration);
  render();
};

const setSelectedFliteCell = (fliteCell: FliteCell) => {
  userConfiguration.fliteCell = fliteCell;
  updateUI(userConfiguration);
  render();
};

let sliderPosition: number;
const setSliderPosition = (position: number, rerender = true) => {
  sliderPosition = position;
  if (rerender) {
    updateUI(userConfiguration);
    render();
  }
};

const render = async () => {
  await LineChart({
    userConfiguration,
    setSelectedPropeller,
    sliderPosition,
    setSliderPosition,
  });
};

updateUI(userConfiguration);
render();

window.addEventListener("resize", function () {
  render();
});

window.addEventListener("load", () => {
  // Configures and shows the config FAB
  setupFAB();
  if (shopifyConfig.units === "US") {
    document
      .querySelectorAll(".ha-weight-si")
      .forEach((el) => el.classList.add("ha-hidden"));
    document
      .querySelectorAll(".ha-weight-us")
      .forEach((el) => el.classList.remove("ha-hidden"));
  }
});

handleBoardType(setBoardType);
handlePropellerButtons(setSelectedPropeller);
handleFliteCellButtons(setSelectedFliteCell);
handleRiderWeight(setRiderWeight);
handleWingType(setWingType);
