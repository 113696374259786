import {
  BoardType,
  FliteCell,
  Propeller,
  Weight,
  WingType,
} from "./graphDataGen";

import { orderedPropellersList } from "./plotLineChart";

export type UserConfiguration = {
  boardType: BoardType;
  propeller: Propeller;
  weight: Weight;
  fliteCell: FliteCell;
  wingType: WingType;
};

const orderedFliteCellList = [
  FliteCell.Explore,
  FliteCell.Sport,
  FliteCell.Nano,
];

const propellerMapping = [
  {
    id: "ha-TrueGlidePropButton",
    propeller: Propeller.TrueGlideProp,
  },
  {
    id: "ha-PropAndGuardButton",
    propeller: Propeller.PropAndGuard,
  },
  {
    id: "ha-JetButton",
    propeller: Propeller.Jet,
  },
];

const fliteCellMapping = [
  {
    id: "ha-FliteCellExploreButton",
    fliteCell: FliteCell.Explore,
  },
  {
    id: "ha-FliteCellSportButton",
    fliteCell: FliteCell.Sport,
  },
  {
    id: "ha-FliteCellNanoButton",
    fliteCell: FliteCell.Nano,
  },
];

function getIndexInParent(element: HTMLElement | null) {
  return Array.from(element?.parentElement?.children ?? [])
    .filter((elem) => (elem as HTMLElement).offsetParent !== null)
    .indexOf(element as any);
}

function updateFliteCellButtons(config: UserConfiguration) {
  fliteCellMapping.forEach((mapping) => {
    const button = document.getElementById(mapping.id) as HTMLInputElement;
    if (
      config.boardType === BoardType.Flitescooter &&
      mapping.fliteCell === FliteCell.Nano
    ) {
      button.classList.add("ha-hidden");
      if (config.fliteCell === mapping.fliteCell) {
        const button = document.getElementById(
          fliteCellMapping[0].id
        ) as HTMLInputElement;
        button.click();
      }
    } else {
      button.classList.remove("ha-hidden");
    }
    if (config.fliteCell === mapping.fliteCell) {
      button.classList.add("ha-selected");
    } else {
      button.classList.remove("ha-selected");
    }
  });
  const p = 100 / (config.boardType === BoardType.Flitescooter ? 2 : 3);
  const bg = document.getElementById(
    "ha-fliteCellSelectedOptionBackground"
  ) as HTMLInputElement;
  bg.style.left = `calc(${
    getIndexInParent(
      document.getElementById(
        fliteCellMapping.find(({ fliteCell }) => fliteCell === config.fliteCell)
          ?.id ?? ""
      )
    ) * p
  }% - 1px)`;
  bg.style.width = `calc(${p}% + 2px)`;
}

export function handleFliteCellButtons(
  callback: (fliteCell: FliteCell) => void
) {
  fliteCellMapping.forEach((mapping) => {
    const button = document.getElementById(mapping.id) as HTMLInputElement;
    button.addEventListener("click", () => {
      callback(mapping.fliteCell);
    });
  });
}

function updatePropellerButtons(config: UserConfiguration) {
  propellerMapping.forEach((mapping) => {
    const button = document.getElementById(mapping.id) as HTMLInputElement;
    if (
      config.boardType === BoardType.Flitescooter &&
      mapping.propeller !== Propeller.Jet
    ) {
      button.classList.add("ha-hidden");
      if (config.propeller === mapping.propeller) {
        const button = document.getElementById(
          propellerMapping[2].id
        ) as HTMLInputElement;
        button.click();
      }
    } else {
      button.classList.remove("ha-hidden");
    }
    if (config.propeller === mapping.propeller) {
      button.classList.add("ha-selected");
    } else {
      button.classList.remove("ha-selected");
    }
  });
  const bg = document.getElementById(
    "ha-propellerSelectedOptionBackground"
  ) as HTMLInputElement;
  const p = 100 / (config.boardType === BoardType.Flitescooter ? 1 : 3);
  bg.style.left = `calc(${
    getIndexInParent(
      document.getElementById(
        propellerMapping.find(({ propeller }) => propeller === config.propeller)
          ?.id ?? ""
      )
    ) * p
  }% - 1px)`;
  bg.style.width = `calc(${p}% + 2px)`;
}

export function handleBoardType(callback: (boardType: BoardType) => void) {
  const element = document.getElementById("ha-BoardType") as HTMLInputElement;
  element.addEventListener("change", () => {
    callback(element.value as BoardType);
  });
}

export function handlePropellerButtons(
  callback: (propeller: Propeller) => void
) {
  propellerMapping.forEach((mapping) => {
    const button = document.getElementById(mapping.id) as HTMLInputElement;
    button.addEventListener("click", () => {
      callback(mapping.propeller);
    });
  });
}

function updateBoardTypeOptions(config: UserConfiguration) {
  const element = document.getElementById("ha-BoardType") as HTMLInputElement;
  element.value = config.boardType.toString();
  const panelTitle = document.getElementById(
    "ha-sidePanelTitle"
  ) as HTMLHeadingElement;
  const panelDescription = document.getElementById(
    "ha-sidePanelDescription"
  ) as HTMLHeadingElement;
  if (config.boardType === BoardType.Flitescooter) {
    panelTitle.innerText = "Flitescooter Setup";
    panelDescription.innerText =
      "Select your weight and battery to see your average foil time, distance and speed.";
  } else {
    panelTitle.innerText = "Fliteboard Setup";
    panelDescription.innerText =
      "Select your weight, wing set and battery to see your average foil time, distance and speed.";
  }
}

function updateRiderWeighOptions(config: UserConfiguration) {
  const element = document.getElementById("ha-RiderWeight") as HTMLInputElement;
  element.value = config.weight.toString();
}

export function handleRiderWeight(callback: (weight: Weight) => void) {
  const element = document.getElementById("ha-RiderWeight") as HTMLInputElement;
  element.addEventListener("change", () => {
    callback(parseInt(element.value) as Weight);
  });
}

function updateWingTypeOptions(config: UserConfiguration) {
  const element = document.getElementById("ha-WingType") as HTMLSelectElement;
  if (
    config.boardType === BoardType.Flitescooter &&
    config.wingType !== WingType.CruiserJet1800
  ) {
    element.value = WingType.CruiserJet1800;
    element.dispatchEvent(new Event("change"));
  } else {
    element.value = config.wingType;
  }
  if (config.boardType === BoardType.Flitescooter) {
    element.classList.add("ha-sidePanelSelectStatic");
    element.tabIndex = -1;
  } else {
    element.classList.remove("ha-sidePanelSelectStatic");
    element.tabIndex = 0;
  }
  for (let i = 0; i < element.options.length; i++) {
    const option = element.options[i];
    if (
      config.boardType === BoardType.Flitescooter &&
      option.value !== WingType.CruiserJet1800
    ) {
      option.classList.add("ha-hidden");
    } else {
      option.classList.remove("ha-hidden");
    }
    if (option.value === WingType.CruiserJet1800) {
      if (config.boardType === BoardType.Flitescooter) {
        option.innerHTML = "Cruiser 1800";
      } else {
        option.innerHTML = "Cruiser Jet 1800 (Beginner)";
      }
    }
  }
}

export function handleWingType(callback: (wingType: WingType) => void) {
  const element = document.getElementById("ha-WingType") as HTMLInputElement;
  element.addEventListener("change", () => {
    callback(element.value as WingType);
  });
}

export function updateUI(config: UserConfiguration) {
  updateBoardTypeOptions(config);
  updatePropellerButtons(config);
  updateFliteCellButtons(config);
  updateRiderWeighOptions(config);
  updateWingTypeOptions(config);
}
