import "d3-transition";

import {
  BoardType,
  GraphDatum,
  Propeller,
  getFoilingTime,
} from "./graphDataGen";
import { ScaleLinear, ScaleOrdinal, Selection } from "d3";
import { axisBottom, axisLeft } from "d3-axis";
import { curveMonotoneX, line } from "d3-shape";
import { extent, group, range } from "d3-array";
import { scaleLinear, scaleOrdinal } from "d3-scale";

import { UserConfiguration } from "./handleUI";
import { drag } from "d3-drag";
import { interpolateBasis } from "d3-interpolate";
import { select } from "d3-selection";
import { shopifyConfig } from "./shopifyConfig";

let lastUserConfiguration: UserConfiguration;

type Props = {
  userConfiguration: UserConfiguration;
  setSelectedPropeller: (propeller: Propeller) => void;
  sliderPosition?: number;
  setSliderPosition: (position: number, rerender?: boolean) => void;
};

export const orderedPropellersList = [
  Propeller.TrueGlideProp,
  Propeller.PropAndGuard,
  Propeller.Jet,
];

let height = 0;
let width = 0;
let sliderPosition = 0;

let svg: Selection<SVGGElement, unknown, HTMLElement, any>;
let selectedLineBounds: {
  graphStartX: number;
  graphEndX: number;
};

export const LineChart = async ({
  userConfiguration,
  setSelectedPropeller: _setSelectedPropeller,
  sliderPosition: _sliderPosition,
  setSliderPosition: _setSliderPosition,
}: Props) => {
  const wrapper = select("#ha-graph").node() as SVGElement;
  const outerWidth = wrapper.getBoundingClientRect().width;
  const outerHeight = wrapper.getBoundingClientRect().height;

  const margin = {
    top: 80,
    right: 20,
    bottom: 75,
    left: 50,
  };
  let _width = outerWidth - margin.left - margin.right;
  let _height = outerHeight - margin.top - margin.bottom;

  if (_height != height || _width != width) {
    select(".ha-graph").html("");
  }

  width = _width;
  height = _height;

  const minAvgSpeed = shopifyConfig.units === "SI" ? 10 : 5;
  const maxAvgSpeed = shopifyConfig.units === "SI" ? 50 : 35;
  const minFoilingTime = 0;
  const maxFoilingTime = 4;

  if (!lastUserConfiguration) setLastConfiguration(userConfiguration);
  const configurationChange = didConfigurationChange(
    userConfiguration,
    lastUserConfiguration
  );
  setLastConfiguration(userConfiguration);

  renderBaseSVG(margin);

  svg = select(".ha-graph");
  // const generateDataMemo = memoizee(generateData, { max: 2 });
  // const data = generateDataMemo(
  // as ReturnType<typeof generateData>
  const data = generateData(userConfiguration, minAvgSpeed, maxAvgSpeed);
  const groupedData = group(data, (d) => d.propeller);

  const xScale = scaleLinear()
    .domain([minAvgSpeed, maxAvgSpeed])
    .range([0, width]);
  renderXAxis(xScale);
  renderXAxisLabel();

  const yScale = scaleLinear()
    .domain([minFoilingTime, maxFoilingTime])
    .range([height, 0]);
  renderYAxis(yScale);
  renderYAxisLabel();

  const selectedLineData = getSelectedLineData(
    data,
    userConfiguration.propeller
  );
  selectedLineBounds = getSelectedLineBounds(selectedLineData, xScale);

  const bounder = () =>
    getBoundedSliderPosition(
      selectedLineBounds.graphStartX,
      selectedLineBounds.graphEndX
    );

  sliderPosition =
    _sliderPosition === undefined ? bounder()(0) : _sliderPosition * width;

  const setSliderPosition = (x: number, rerender?: boolean) => {
    _setSliderPosition(bounder()(x) / width, rerender);
  };

  if (configurationChange) {
    sliderPosition = bounder()(sliderPosition);
    _setSliderPosition(sliderPosition / width, false);
  }

  const setSelectedPropeller = (propeller: Propeller) => {
    _setSelectedPropeller(propeller);
  };

  const colorOrdinal = scaleOrdinal<Propeller, string>()
    .domain(orderedPropellersList)
    .range(["#635c49", "#EB8B33", "#B0AEA5"]);

  renderLines(
    userConfiguration,
    groupedData,
    xScale,
    yScale,
    userConfiguration.propeller,
    setSelectedPropeller,
    colorOrdinal
  );

  const foilingTime = getFoilingTimeFromSliderPosition(selectedLineData);

  const avgSpeed = getAvgSpeedAtPoint(xScale);

  const sliderWrapper = renderSliderWrapper();

  renderGraphPoint(foilingTime, yScale, sliderWrapper, configurationChange);

  renderSliderLine(sliderWrapper);

  renderSliderPopup(avgSpeed, foilingTime);

  renderSliderXAxisPopup(avgSpeed);

  renderSliderHandle(xScale, setSliderPosition);
};

function renderBaseSVG(margin: {
  top: number;
  right: number;
  bottom: number;
  left: number;
}) {
  select("#ha-graph")
    .selectAll(".ha-graph")
    .data([null])
    .enter()
    .append("g")
    .attr("class", "ha-graph")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
}

function getBoundedSliderPosition(graphStartX: number, graphEndX: number) {
  return (x: number) => Math.min(Math.max(graphStartX, x), graphEndX);
}

function getSelectedLineBounds(
  selectedLine: GraphDatum[],
  xScale: ScaleLinear<number, number, never>
) {
  const selectedDomain = extent(selectedLine, (d) => d.averageSpeed)!;

  return {
    graphStartX: xScale(selectedDomain[0]!),
    graphEndX: xScale(selectedDomain[1]!),
  };
}

function getSelectedLineData(data: GraphDatum[], selectedPropeller: Propeller) {
  return data.filter((d) => d.propeller === selectedPropeller);
}

function renderSliderWrapper() {
  const sliderWrapper = svg
    .selectAll(".ha-slider-wrapper")
    .data([null])
    .enter()
    .append("g")
    .attr("class", "ha-slider-wrapper");

  svg
    .select(".ha-slider-wrapper")
    .attr("transform", `translate(${sliderPosition}, 0)`);

  return sliderWrapper;
}

function generateData(
  userConfiguration: UserConfiguration,
  minAvgSpeed: number,
  maxAvgSpeed: number
) {
  return orderedPropellersList
    .map((propeller) =>
      range(minAvgSpeed, maxAvgSpeed + 1, 0.5)
        .map(
          (averageSpeed) =>
            ({
              propeller,
              averageSpeed,
              foilingTime:
                userConfiguration.boardType === BoardType.Flitescooter &&
                propeller !== Propeller.Jet
                  ? 0.000001
                  : getFoilingTime({
                      ...userConfiguration,
                      propeller: propeller,
                      averageSpeed: averageSpeed,
                    }),
            } as GraphDatum)
        )
        .filter((d) => d.foilingTime > 0)
    )
    .flatMap((d) => d);
}

function renderSliderLine(
  sliderWrapper: Selection<SVGGElement, null, SVGGElement, unknown>
) {
  sliderWrapper
    .append("line")
    .attr("class", "ha-slider-line")
    .attr("y1", -55)
    .attr("y2", height + 25)
    .attr("x1", 0)
    .attr("x2", 0);
}

function renderSliderHandle(
  xScale: ScaleLinear<number, number, never>,
  setSliderPosition: (position: number) => void
) {
  svg
    .selectAll(".ha-slider-handle")
    .data([null])
    .enter()
    .append("rect")
    .attr("width", 66)
    .attr("height", 28)
    .attr("y", height + 11)
    .attr("fill", "transparent")
    .attr("class", "ha-slider-handle")
    .attr("tabindex", 0)
    .call(
      drag<SVGRectElement, any>().on("drag", (event) =>
        setSliderPosition(event.x)
      )
    )
    .call((selection) => {
      selection.on("keydown", (event) => {
        if (event.key === "ArrowLeft") {
          setSliderPosition(xScale(getAvgSpeedAtPoint(xScale) - 1));
        } else if (event.key === "ArrowRight") {
          setSliderPosition(xScale(getAvgSpeedAtPoint(xScale) + 1));
        }
      });
    });

  svg.select(".ha-slider-handle").attr("x", sliderPosition - 68 / 2);
}

function renderGraphPoint(
  foilingTime: number,
  yScale: ScaleLinear<number, number, never>,
  sliderWrapper: Selection<SVGGElement, null, SVGGElement, unknown>,
  configurationChange: boolean
) {
  sliderWrapper
    .append("circle")
    .attr("class", "ha-point")
    .attr("r", 9)
    .attr("stroke", "white")
    .attr("stroke-width", 3)
    .attr("fill", "#8D8D8D");

  let point = svg.select(".ha-point");
  if (configurationChange) {
    // @ts-ignore
    point = point.transition().duration(500);
  }
  point.attr("cy", getGraphHeightAtPoint(foilingTime, yScale));
}

function getFoilingTimeFromSliderPosition(selectedLine: GraphDatum[]) {
  var interp = interpolateBasis(
    selectedLine.map(function (d) {
      return d.foilingTime;
    })
  );

  let scale = scaleLinear()
    .domain([selectedLineBounds.graphStartX, selectedLineBounds.graphEndX])
    .range([0, 1]);

  return interp(scale(sliderPosition));
}

function getGraphHeightAtPoint(
  foilingTime: number,
  yScale: ScaleLinear<number, number, never>
) {
  return yScale(foilingTime);
}

function getAvgSpeedAtPoint(xScale: ScaleLinear<number, number, never>) {
  return xScale.invert(sliderPosition);
}

function renderLines(
  userConfiguration: UserConfiguration,
  groupedData: d3.InternMap<Propeller, GraphDatum[]>,
  xScale: ScaleLinear<number, number, never>,
  yScale: ScaleLinear<number, number, never>,
  selectedPropeller: Propeller,
  setSelectedPropeller: (propeller: Propeller) => void,
  colorOrdinal: ScaleOrdinal<Propeller, string, never>
) {
  const plot = (lines) => {
    lines.attr("d", (d) =>
      line<GraphDatum>()
        .x(function (d: any) {
          return xScale(d.averageSpeed);
        })
        .y(function (d: any) {
          return yScale(d.foilingTime);
        })
        .curve(curveMonotoneX)(d[1])
    );
  };
  const lines = svg
    .selectAll(".ha-line")
    .data(groupedData)
    .attr("class", "ha-line");

  lines
    .enter()
    .append("path")
    .attr("class", "ha-line")
    .on("click", (_, d) => {
      setSelectedPropeller(d[0]);
    })
    .call(plot);

  svg
    .selectAll(".ha-line")
    .data(groupedData)
    .classed("selected", (d) => d[0] === selectedPropeller)
    .classed(
      "ha-hidden",
      (d) =>
        d[0] !== Propeller.Jet &&
        userConfiguration.boardType === BoardType.Flitescooter
    )
    .attr("stroke", (d) => colorOrdinal(d[0]));

  lines.transition().duration(500).call(plot);
}

function renderSliderPopup(avgSpeed: number, foilTime: number) {
  const popup = svg
    .selectAll(".ha-slider-popup")
    .data([null])
    .enter()
    .append("g")
    .attr("class", "ha-slider-popup");

  const sliderPopupText = popup
    .append("text")
    .attr("class", "ha-slider-popup-text");

  const distanceUnit = shopifyConfig.units === "SI" ? "km" : "mi";
  const speedUnit = shopifyConfig.units === "SI" ? "km/h" : "mph";

  sliderPopupText
    .append("tspan")
    .attr("class", "ha-slider-popup-text-l1")
    .attr("x", 0)
    .attr("dy", 0);
  select(".ha-slider-popup-text-l1").text(
    `${getFormattedTime(foilTime)} / travel ${(foilTime * avgSpeed).toFixed(
      0
    )}${distanceUnit}`
  );

  sliderPopupText
    .append("tspan")
    .attr("class", "ha-slider-popup-text-l2")
    .attr("x", 0)
    .attr("dy", 20);
  select(".ha-slider-popup-text-l2").text(
    `Average speed of ${avgSpeed.toFixed(0)}${speedUnit}`
  );

  const text = svg.select(".ha-slider-popup-text");

  const bbox = (text.node() as SVGTextElement).getBBox();
  const paddingX = 16;
  const paddingY = 12;

  popup.insert("rect", "text").attr("class", "ha-slider-popup-rect");

  svg
    .select(".ha-slider-popup-rect")
    .attr("x", () => {
      return bbox.x - paddingX;
    })
    .attr("y", bbox.y - paddingY)
    .attr("width", bbox.width + paddingX * 2)
    .attr("height", bbox.height + paddingY * 2);

  const minPos = bbox.width / 2 + paddingX - 10;
  const maxPos = width - bbox.width / 2 - paddingX + 10;

  const currentPosition = Math.min(Math.max(sliderPosition, minPos), maxPos);

  svg
    .select(".ha-slider-popup")
    .attr("transform", `translate(${currentPosition}, -45)`);
}

function renderSliderXAxisPopup(avgSpeed: number) {
  const popup = svg
    .selectAll(".ha-slider-x-axis-popup")
    .data([null])
    .enter()
    .append("g")
    .attr("class", "ha-slider-x-axis-popup");

  popup.append("text").attr("class", "ha-slider-x-axis-popup-text");

  const text = svg.select(".ha-slider-x-axis-popup-text");
  text.text(avgSpeed.toFixed(0));

  const bbox = (text.node() as SVGTextElement).getBBox();
  const paddingX = 24;
  const paddingY = 6;

  popup
    .insert("rect", "text")
    .attr("rx", (bbox.height + paddingY * 2) / 2)
    .attr("ry", (bbox.height + paddingY * 2) / 2)
    .attr("class", "ha-slider-x-axis-popup-rect")
    .attr("x", bbox.x - paddingX)
    .attr("y", bbox.y - paddingY)
    .attr("width", bbox.width + paddingX * 2)
    .attr("height", bbox.height + paddingY * 2);

  const wrapper = popup
    .append("g")
    .attr("class", "ha-slider-arrows")
    .attr("pointer-events", "none")
    .attr("fill", "white");

  wrapper
    .append("path")
    .attr(
      "d",
      "M8 12.5746L8 5.42539C8 4.95275 7.40482 4.74397 7.10957 5.11304L4.24988 8.68765C4.10379 8.87026 4.10379 9.12974 4.24988 9.31235L7.10957 12.887C7.40482 13.256 8 13.0472 8 12.5746Z"
    )
    .attr("transform", `translate(${-16}, -14)`);

  wrapper
    .append("path")
    .attr(
      "d",
      "M10 5.42539L10 12.5746C10 13.0472 10.5952 13.256 10.8904 12.887L13.7501 9.31235C13.8962 9.12974 13.8962 8.87026 13.7501 8.68765L10.8904 5.11304C10.5952 4.74397 10 4.95275 10 5.42539Z"
    )
    .attr("transform", `translate(${+16}, -14)`);

  svg.select(".ha-slider-arrows").attr("transform", `translate(${-9}, 0)`);

  const minPos = bbox.width / 2 + paddingX - 16;
  const maxPos = width - bbox.width / 2 - paddingX + 16;

  const currentPosition = Math.min(Math.max(sliderPosition, minPos), maxPos);

  svg
    .select(".ha-slider-x-axis-popup")
    .attr("transform", `translate(${currentPosition}, ${height + 30})`);
}

function renderYAxis(yScale: ScaleLinear<number, number, never>) {
  const yAxis = axisLeft(yScale);
  yAxis.tickValues([0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4]);
  yAxis.tickFormat((d) => `${d.valueOf().toFixed(0)}`);
  yAxis.tickSize(-width);

  svg
    .selectAll(".ha-y-axis")
    .data([null])
    .enter()
    .append("g")
    .attr("class", "ha-y-axis")
    .call(yAxis)
    .call((g) => g.select(".domain").remove())
    .call((g) => g.selectAll(".tick line").attr("class", "ha-grid-line"))
    .call((g) =>
      g
        .selectAll(".tick text")
        .attr("transform", "translate(-10,0)")
        .attr("display", (d, i) => (i % 2 === 0 ? "block" : "none"))
    );
}

function renderXAxis(xScale: ScaleLinear<number, number, never>) {
  const xAxis = axisBottom(xScale);
  xAxis.tickValues(
    shopifyConfig.units === "SI"
      ? [10, 20, 30, 40, 50]
      : [5, 10, 15, 20, 25, 30, 35]
  );

  svg
    .selectAll(".ha-x-axis")
    .data([null])
    .enter()
    .append("g")
    .attr("transform", "translate(0," + (height + 10) + ")")
    .attr("class", "ha-x-axis")
    .call(xAxis)
    .call((g) => g.select(".domain").remove())
    .call((g) => g.selectAll(".tick line").remove());
}

function renderYAxisLabel() {
  const FoilTimeAxisLabelWrapper = svg
    .selectAll(".ha-Y-AxisLabelWrapper")
    .data([null])
    .enter()
    .append("g")
    .attr("class", "ha-Y-AxisLabelWrapper")
    .attr("transform", `translate(-40, ${height / 2}) rotate(-90)`);

  const foilTimeLabel = FoilTimeAxisLabelWrapper.append("text")
    .attr("text-anchor", "middle")
    .attr("class", "ha-Y-AxisLabel");

  foilTimeLabel.append("tspan").text("Foil time ").attr("class", "ha-FoilTime");
  foilTimeLabel.append("tspan").text("hrs");
}

function renderXAxisLabel() {
  const axisLabelWrapper = svg
    .selectAll(".ha-X-AxisLabelWrapper")
    .data([null])
    .enter()
    .append("g")
    .attr("class", "ha-X-AxisLabelWrapper")
    .attr("transform", `translate(0,${height + 60})`);

  const speedUnit = shopifyConfig.units === "SI" ? "km/h" : "mph";

  axisLabelWrapper
    .append("text")
    .attr("class", "ha-X-AxisLabel")
    .text("Min foil speed")
    .append("tspan")
    .text(` ${speedUnit}`)
    .attr("class", "ha-speed");

  axisLabelWrapper
    .append("text")
    .attr("x", width)
    .attr("class", "ha-X-AxisLabel")
    .attr("text-anchor", "end")
    .text("Max foil speed");
}

function getFormattedTime(time: number) {
  const foilTime = time * 60;
  if (foilTime < 60) {
    return `${Math.floor(foilTime)} mins`;
  } else {
    const hours = Math.floor(foilTime / 60);
    const minutes = Math.floor(foilTime % 60);
    // if (minutes === 0) {
    //   return `${hours} hours`;
    // }
    const hoursText = hours === 1 ? "hr" : "hrs";
    const minutesText = minutes === 1 ? "min" : "mins";
    return `${hours} ${hoursText} ${minutes} ${minutesText}`;
  }
}

function didConfigurationChange(
  userConfiguration: UserConfiguration,
  lastUserConfiguration: UserConfiguration
) {
  return (
    userConfiguration.boardType !== lastUserConfiguration.boardType ||
    userConfiguration.weight !== lastUserConfiguration.weight ||
    userConfiguration.wingType !== lastUserConfiguration.wingType ||
    userConfiguration.fliteCell !== lastUserConfiguration.fliteCell ||
    userConfiguration.propeller !== lastUserConfiguration.propeller
  );
}

function setLastConfiguration(userConfiguration: UserConfiguration) {
  lastUserConfiguration = {
    ...userConfiguration,
  };
}
